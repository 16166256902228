import { Link, navigate } from "gatsby";
import React from "react";
import { Crumb } from "../../types";

type BreadCrumbProps = {
	crumbs: Crumb[];
	className?: string;
};

export default function Breadcrumb({ crumbs, className }: BreadCrumbProps) {
	const changeRoute = (url: string | null) => {
		if (!url) {
			return;
		} else {
			navigate(url);
		}
	};
	return (
		<nav
			id="breadcrumb"
			className={`flex my-2  lg:pr-[7px] pr-[7px] mb-5 ${className ? className : ""}`}
			aria-label="Breadcrumb"
		>
			<ol className="inline-flex  items-start space-x-1">
				{crumbs.length > 0 && (
					<li className="inline-flex items-center">
						<Link
							to="/"
							className="inline-flex items-center text-[15px] font-medium text-black select-none"
						>
							<span className=" text-[15px] font-medium text-black font-sofiaRegular font-regular  text-ellipsis hover:underline">
								Home
							</span>
						</Link>
					</li>
				)}
				{crumbs.map((crumb, index) => {
					const isLast = index === crumbs.length - 1;
					return (
						<React.Fragment key={index}>
							{!isLast ? (
								<li
									// onClick={() => changeRoute(crumb.link)}
									// className="cursor-pointer"
									className={`breadcrumb-item select-none ${crumb.link ? "cursor-pointer" : "cursor-default"
										}`}
									key={index}
								>
									<Link to={crumb.link as string}>
										<div className="flex items-center">
											{crumb.label &&
												<svg
													aria-hidden="true"
													className="w-4 h-4 text-black"
													fill="currentColor"
													viewBox="0 0 20 20"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
														clipRule="evenodd"
													></path>
												</svg>
											}
											<span className="ml-1 text-[15px] overflow-hidden font-medium md:max-w-full truncate max-w-[100px] text-black hover:underline md:ml-2 font-sofiaRegular font-regular ">
												{crumb.label?.replace("&amp;", "&")}
											</span>
										</div>
									</Link>
								</li>
							) : (
								// <span key={index}>
								<li className="breadcrumb-item cursor-default select-none" key={index}>
									<div className="flex items-center">
										{crumb.label &&
											<svg
												aria-hidden="true"
												className="w-4 h-4 text-black"
												fill="currentColor"
												viewBox="0 0 20 20"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
													clipRule="evenodd"
												></path>
											</svg>
										}
										<span className="ml-1 text-[15px]  text-primary md:ml-2 overflow-hidden truncate md:max-w-full text-ellipsis max-w-[150px] font-sofiaRegular font-regular">
											{crumb.label?.replace("&amp;", "&")}
										</span>
									</div>
								</li>
								// </span>
							)}
						</React.Fragment>
					);
				})}
			</ol>
		</nav>
	);
}
