import React, { useState, FC, useEffect } from "react";
import PageLoader from "../common/Loader/PageLoader";
import {
  getUserById,
  updateAccountDetails,
} from "../../services/woocommerceServices/Customer";
import EditAccountDetails from "./editAccountDetails";
import EditPassword from "../EditPasswordComponent/editPassword";
import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import { toast } from "react-toastify";
import { EditSvg, EmailSvg, ProfileSvg, UsernameSvg } from "../common/Svg";
import { useSelector } from "react-redux";
import { rootReducer } from "../../store";

const AccountDetails = () => {
  const { isLoggedIn, userId, email } = useJWTChecker();
  const [accountDetails, setAccountDetails] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [isShowEditForm, setIsShowEditForm] = useState<boolean>(false);
  const [isShowChangePasswordForm, setIsShowChangePasswordForm] =
    useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);

  const { refreshUI } = useSelector((state: rootReducer) => state.cart);

  useEffect(() => {
    setIsShowEditForm(false);
    setIsShowChangePasswordForm(false);
  }, [refreshUI]);

  useEffect(() => {
    isLoggedIn && email && userId && getCustomerAccountDetails();
  }, [isLoggedIn, email, userId]);

  const getCustomerAccountDetails = async () => {
    if (isLoggedIn && email && userId) {
      let userInfo: any;
      let getStoredUserDetails =
        localStorage.getItem("userAccountDetails") || null;

      const localUserDetails = getStoredUserDetails
        ? JSON.parse(getStoredUserDetails)
        : false;

      if (localUserDetails) {
        userInfo = localUserDetails;
      } else {
        setLoader(true);
        const fetchUserInfo: any = await getUserById(userId);
        // console.log(fetchUserInfo, "<<-- fetchUserInfo")

        // localStorage.setItem(
        //   "userAccountDetails",
        //   JSON.stringify(fetchUserInfo?.data)
        // );
        userInfo = fetchUserInfo?.data;
      }

      if (userInfo) {
        setAccountDetails(userInfo);
        setLoader(false);
      } else {
        setAccountDetails([]);
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  const handelEditAccountDetails = async (data: any) => {
    setButtonLoader(true);

    if (userId) {
      let updatedData: any = {
        first_name: data?.firstname,
        last_name: data?.lastname,
        email: data?.email,
      };
      const getUpdateResponse: any = await updateAccountDetails(
        userId,
        updatedData
      );

      if (getUpdateResponse?.data) {
        setAccountDetails(getUpdateResponse?.data);
        localStorage.setItem(
          "userAccountDetails",
          JSON.stringify(getUpdateResponse?.data)
        );
        toast.success("Account details updated successfully.");
      } else {
        toast.error("error, please try again later!");
        setButtonLoader(false);
        setIsShowEditForm(false);
      }
    }
    setButtonLoader(false);
    setIsShowEditForm(false);
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <>
          {!isShowEditForm && !isShowChangePasswordForm && (
            <>
              <div className="mx-auto  border w-full rounded-[12px] overflow-hidden bg-white ">
                <div className="  py-3 px-3 flex justify-between items-center border-b bg-bg-grey">
                  <h2 className=" font-sofiaSemibold font-semiBold text-[20px] ">
                    {" "}
                    Basic information
                  </h2>
                  <span className="text-gray-600 cursor-pointer">
                    <button
                      onClick={() => {
                        setIsShowEditForm(true);
                      }}
                      className="text-blue-500 hover:text-blue-600"
                    >
                      <EditSvg />
                    </button>
                  </span>
                </div>
                <div className="px-3 py-4 flex flex-col gap-3">
                  {(accountDetails?.first_name ||
                    accountDetails?.last_name) && (
                    <div className=" flex items-center">
                      <ProfileSvg />
                      <span className="ml-2 font-sofiaRegular font-regular">
                        {accountDetails?.first_name} {accountDetails?.last_name}
                      </span>
                    </div>
                  )}
                  <div className=" flex items-center">
                    <EmailSvg />

                    <span className=" font-sofiaRegular font-regular ml-2">
                      {accountDetails?.email}
                    </span>
                  </div>
                </div>
              </div>

              <div className="w-full mx-auto p-3 my-4  rounded-[12px] border overflow-hidden bg-bg-grey ">
                <div className="font-bold  flex justify-between items-center">
                  <h2 className="font-sofiaSemibold font-semiBold text-[20px]">
                    {" "}
                    Change Password{" "}
                  </h2>
                  <span className="text-gray-600 cursor-pointer">
                    <button
                      onClick={() => {
                        setIsShowChangePasswordForm(true);
                      }}
                      className="text-blue-500 hover:text-blue-600"
                    >
                      <EditSvg />
                    </button>
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {isShowEditForm && (
        <EditAccountDetails
          buttonLoading={buttonLoader}
          data={accountDetails}
          onPressSaveBtn={(data: any) => {
            handelEditAccountDetails(data);
          }}
          onPressCancelBtn={() => {
            setIsShowEditForm(false);
          }}
        />
      )}
      {isShowChangePasswordForm && (
        <EditPassword
          onSuccessUpdatePassword={() => {
            setIsShowChangePasswordForm(false);
          }}
          onPressCancelBtn={() => {
            setIsShowChangePasswordForm(false);
          }}
        />
      )}
    </>
  );
};

export default AccountDetails;
