import * as Yup from "yup";

const passwordSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .required("Old password is required")
    .min(5, "Password should be at least 5 characters long"),
  newpassword: Yup.string()
    .required("New password is required")
    .min(5, "Password should be at least 5 characters long"),
  confirmpassword: Yup.string()
    .required("Please re-enter password")
    .oneOf([Yup.ref("newpassword")], "Passwords must match"),
    
});

export default passwordSchema;
