import React, { useCallback, useEffect, useState } from "react";
import LoginContent from "../../components/LoginContent";
import RegisterContent from "../../components/RegisterContent";

import Layout from "../../components/layout/Layout";
import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import MyAccountList from "../../components/common/List/MyAccountList";
import AccountDetails from "../../components/MyAccount/AccountDetails";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Crumb } from "../../types";
import { navigate, Script } from "gatsby";
import PrivateComponent from "../../utils/PrivateComponent/PrivateComponent";
import { Helmet } from "react-helmet";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";

const Myaccount = () => {
  const { isLoggedIn, userId, email } = useJWTChecker();
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setCrumbs([{ label: "My account", link: null }]);
    setIsClient(true);
  }, []);

  // if (isClient && !localStorage.getItem("user") && !isLoggedIn) {
  //   navigate("/login");
  // }

  return (
    <PrivateComponent>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Spartanpeptides | My Account</title>
      </Helmet>
      <Layout>
        <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] md:mb-[100px] mb-[50px] home-faq fadeInUp-animation contain">
          <Breadcrumb crumbs={crumbs} />
          <div className="flex md:flex-row flex-col md:gap-8 gap-3">
            <MyAccountList selectedOption={"My Account"} />
            <div className="md:w-3/4 w-full flex  flex-col mt-4">
              <AccountDetails />
            </div>
          </div>
        </div>
      </Layout>
    </PrivateComponent>
  );
};

export default Myaccount;


export const Head = (props: any) => {
	return (
		<>
			<title>My Account</title>
      
		</>
	);
};